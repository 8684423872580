import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import menuList from "../data/menulist.json";
import RegistrationForm from "../../../Components/RegistrationPopUp";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import companyLogo from "../assets/companyLogo.svg";

const MobileViewNav = () => {
    const [showDropdown, setShowDropdown] = useState(null);
    const [showRegistrations, setShowRegistrations] = useState(false);
    const navigate = useNavigate();

    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(null);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const getRedirectLink = (data) => {
        if (window.location.href.toString().includes("private-job")) {
            let privateJobUrl = `/private-job/listing?`;
            if (data.name && data.name.toLowerCase() != "all") {
                privateJobUrl += `field=${data.name.toLowerCase()}`;
            }
            return privateJobUrl;
        }
        return data.url;
    };

    return (
        <div className={styles.container}>
            <div className={styles.registration}>
                <p>Get Personalized Healthcare Job Alert</p>
                <button onClick={() => setShowRegistrations(true)}>Register Now</button>
            </div>
            <div className={styles.navbar_container}>
                <div
                    onClick={() => {
                        navigate("/");
                    }}
                    className={styles.breadcom_container}
                >
                    <img src={companyLogo} alt="companyLogo" />
                </div>

                <div ref={dropdownRef} onClick={() => setShowDropdown(showDropdown ? null : "Jobs")} className={styles.menu_list}>
                    <ul>
                        {menuList.map((item) => {
                            return (
                                <li key={item.name}>
                                    {item.child ? (
                                        <div ref={dropdownRef} style={{ position: "relative" }}>
                                            <p>
                                                <span>{item.name}</span>
                                                {/* <img style={{transform:showDropdown === item.name ? "rotate(180deg)"  : "rotate(0deg)"}} src="../publicAssets/utils/downarrow.svg" alt="" srcSet="" />  */}
                                            </p>
                                            <ul style={{ display: showDropdown === item.name ? "flex" : "none" }} className={styles.child_container}>
                                                {item.child.map((item) => {
                                                    return (
                                                        <li
                                                            key={item.url}
                                                            onClick={() => {
                                                                navigate(getRedirectLink(item));
                                                                setShowDropdown(null);
                                                            }}
                                                        >
                                                            <Link to={getRedirectLink(item)}>{item.name}</Link>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    ) : (
                                        <Link to={item.url}>{item.name}</Link>
                                    )}
                                </li>
                            );
                        })}
                    </ul>

                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="31" height="31" rx="10" fill="#197BFF" />
                        <path d="M9.89062 9.23438H22.4225" stroke="white" strokeWidth="3" strokeLinecap="round" />
                        <path d="M9.89062 15.1719L22.4225 15.1719" stroke="white" strokeWidth="3" strokeLinecap="round" />
                        <path d="M16.1562 21.7656L22.4222 21.7656" stroke="white" strokeWidth="3" strokeLinecap="round" />
                    </svg>
                </div>

                {showRegistrations && <RegistrationForm getClose={() => setShowRegistrations(false)} />}
            </div>
        </div>
    );
};

export default MobileViewNav;
