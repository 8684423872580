import React from "react";
import styles from "./index.module.css";

const Button = ({ label, type }) => {
    return (
        <div className={styles.container}>
            <button type={type}>{label}</button>
        </div>
    );
};

export default Button;
