import React, { useState } from "react";
import styles from "./index.module.css";
import TextInput from "../../Components/InputTags/TextInput";
import ReactSelect from "react-select";
import Button from "../../Components/InputTags/Button";
import cityState from "../../Data/cityState.json";
import Textarea from "../../Components/InputTags/Textarea";
import { usePrivateJobContext } from "../../../../Api/privatejobPosting";
import { useNavigate, useParams } from "react-router";
import AuthenticationPopUp from "./Authentication";
import { useSelector } from "react-redux";
import DATA from "../../Data";

const JobForm = () => {
    const { postPatchPrivateJobInfo } = usePrivateJobContext();
    const [city, setCity] = useState([{ value: " ", label: "Please Select State First" }]);
    const [selectedTags, setSelectedTags] = useState([]);
    const navigate = useNavigate();
    const { tab } = useParams();
    const [isJobUpdate, setIsJobUpdate] = useState();
    const { isLoggedIn } = useSelector((state) => state.auth);
    return (
        <div className={styles.container_wrapper}>
            <div className={styles.container}>
                <div className={styles.heading}>
                    <h1>Post a Job in Seconds</h1>
                    <h2>You are just a few steps away from next best hire</h2>
                    <p>Lets get started</p>
                </div>
                <div className={styles.body}>
                    <h2>Job Details</h2>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            const formData = new FormData(event.target);
                            const formDataJSON = Object.fromEntries(formData.entries());
                            formDataJSON.tags = selectedTags;
                            formDataJSON.url = formDataJSON.jobTitle.trim().toLowerCase().replace(/\s+/g, "-");
                            postPatchPrivateJobInfo("/v1/privatejobpost/postprivatejob", formDataJSON, () => {
                                isLoggedIn ? navigate("/private-job/authentication") : navigate("/private-job/listing");
                            });
                        }}
                        action=""
                    >
                        <TextInput label={"Company Name"} placeholder={"Company Name"} name="companyName" required={true} type={"text"} />
                        <TextInput label={"Job Title"} name="jobTitle" required={true} type={"text"} />
                        <TextInput label={"No. of Vacancies Available"} name="numberOfVacancy" required={true} type={"text"} />
                        <div className={styles.select_field}>
                            <p>
                                {"Field"} <span>*</span>
                            </p>
                            <ReactSelect
                                className={styles.select}
                                classNamePrefix="select"
                                defaultValue={{ value: "", label: "Select Field" }}
                                isSearchable={true}
                                name="field"
                                options={DATA.Fields}
                            />
                        </div>
                        <div className={styles.fieldset}>
                            <p>
                                {"Location"} <span>*</span>
                            </p>
                            <div className={styles.grid}>
                                <ReactSelect
                                    options={[
                                        { value: "", label: "Select State" },
                                        ...Object.keys(cityState || {}).map((state) => {
                                            return { value: state.toLowerCase(), label: state };
                                        }),
                                    ]}
                                    onChange={(data) =>
                                        setCity(
                                            cityState?.[data.label]?.map((city) => {
                                                return { value: city.toLowerCase(), label: city };
                                            })
                                        )
                                    }
                                    defaultValue={{ value: "", label: "Select State" }}
                                    name="jobState"
                                    Required={true}
                                />

                                <ReactSelect options={[{ value: "", label: "Select City" }, ...city]} defaultValue={{ value: "", label: "Select City" }} name="jobCity" Required={true} />
                            </div>
                        </div>

                        <Textarea label={"Responsibilities"} name="responsibilities" required={true} type={"textarea"} />

                        <Textarea label={"Qualifications"} name="qualification" required={true} type={"textarea"} />

                        <TextInput label={"How to Apply"} name="howToApply" required={false} type={"text"} />

                        <div className={styles.select_field}>
                            <p>
                                {"Tags"} <span>*</span>
                            </p>
                            <ReactSelect
                                className={styles.select}
                                classNamePrefix="select"
                                onChange={(data) => setSelectedTags(data.map((data) => data.value))}
                                isSearchable={true}
                                name="tags"
                                options={DATA.tags}
                                isMulti
                            />
                        </div>

                        <Button label={"Submit"} type={"submit"} />
                    </form>
                </div>
            </div>
            {tab === "authentication" && (
                <AuthenticationPopUp
                    onClose={() => {
                        navigate("/private-job/postjob");
                    }}
                />
            )}
        </div>
    );
};

export default JobForm;
