import React from "react";
import styles from "./index.module.css";

const AlertEmailCheck = () => {
    return (
        <div className={styles.container_wrapper}>
            <div className={styles.container}>
                <img src="https://cdn-icons-png.flaticon.com/128/7286/7286142.png" alt="" />
                <h2>Verify your email</h2>
                <h3>Please Check your email to verify your details</h3>
            </div>
        </div>
    );
};

export default AlertEmailCheck;
