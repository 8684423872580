import { createContext, useContext, useState } from "react";
import { axiosInstance } from "./Interceptors";
import { toast } from "react-toastify";

const PrivateJobContext = createContext();

export function usePrivateJobContext() {
    return useContext(PrivateJobContext);
}

export function PrivateJobProvider({ children }) {
    const [privateJobInfo, setPrivateJobInfo] = useState({});
    const [privateJobList, setPrivateJobList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const notifySuccess = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message);

    const getPrivateJobInfo = (url, setData, getSimilarPost) => {
        setLoading(true);

        axiosInstance
            .get(url)
            .then((response) => {
                setData && setData(response.data.data);
                setPrivateJobInfo(response.data.data);
                getSimilarPost && getSimilarPost(response.data.data.field);
            })
            .catch((error) => {
                setErrorMessage(error.response.data.message);
                notifyError(error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getPrivateJobList = (url, setData, refreshFunction) => {
        setLoading(true);

        axiosInstance
            .get(url)
            .then((response) => {
                setData && setData(response.data);
                setPrivateJobList(response.data);
                refreshFunction && refreshFunction();
            })
            .catch((error) => {
                setErrorMessage(error.response.data.message);
                notifyError(error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const postPatchPrivateJobInfo = (url, data, getClose, refreshFunction) => {
        setLoading(true);

        axiosInstance
            .post(url, data || privateJobInfo)
            .then((response) => {
                // setSuccessMessage(response.data.message);
                // notifySuccess(response.data.message);
                if (response.data.data && !response.data.data.isUserVerified) {
                    if (localStorage.getItem("unVerifiedJobs")) {
                        const existedJobsIds = JSON.parse(localStorage.getItem("unVerifiedJobs"));
                        existedJobsIds.push(response.data.data.jobId);
                        localStorage.setItem("unVerifiedJobs", JSON.stringify(existedJobsIds));
                    } else {
                        localStorage.setItem("unVerifiedJobs", JSON.stringify([response.data.data.jobId]));
                    }
                }
                getClose();
                refreshFunction && refreshFunction();
            })
            .catch((error) => {
                console.log(error);
                //   setErrorMessage(error.response.data.message)
                notifyError(error.response?.data?.message || error.response?.data?.error || error.response?.data?.data?.error);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    // /register-user-private-job

    const registerUserFromPrivateJob = (url, data, getClose) => {
        setLoading(true);

        axiosInstance
            .post(url, data)
            .then((response) => {
                setSuccessMessage(response.data.message);
                notifySuccess(response.data.message);
                getClose();
            })
            .catch((error) => {
                //console.log(error)
                //   setErrorMessage(error.response.data.message)
                notifyError(error.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const patchPrivateJobInfo = (url, data, getClose, refreshFunction) => {
        setLoading(true);

        axiosInstance
            .patch(url, data)
            .then((response) => {
                setSuccessMessage(response.data.message);
                notifySuccess(response.data.message);
                getClose();
                refreshFunction && refreshFunction();
            })
            .catch((error) => {
                //console.log(error)
                //   setErrorMessage(error.response.data.message)
                notifyError(error.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deletePrivateJobInfo = (url, getClose, refreshFunction) => {
        setLoading(true);

        axiosInstance
            .delete(url)
            .then((response) => {
                setSuccessMessage(response.data.message);
                notifySuccess(response.data.message);
                getClose();
                refreshFunction && refreshFunction();
            })
            .catch((error) => {
                //console.log(error)
                //   setErrorMessage(error.response.data.message)
                notifyError(error.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <PrivateJobContext.Provider
            value={{
                successMessage,
                errorMessage,
                loading,
                getPrivateJobInfo,
                privateJobInfo,
                setPrivateJobInfo,
                postPatchPrivateJobInfo,
                getPrivateJobList,
                privateJobList,
                setPrivateJobList,
                deletePrivateJobInfo,
                patchPrivateJobInfo,
                registerUserFromPrivateJob,
            }}
        >
            {children}
        </PrivateJobContext.Provider>
    );
}
