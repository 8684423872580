import React, { useMemo } from "react";
import styles from "./index.module.css";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Utils from "../../Utils";
import DATA from "../../Data";

const JobContainer = ({ item, keyValue }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchTexts = queryParams.get("searchText");
    const tab = queryParams.get("tab");

    const handleNavigation = useMemo(() => {
        return `/private-job/details/${Utils.convertToSlug(item.jobTitle)}--${item._id}?searchText=${searchTexts || ""}&tab=${tab || 1}`;
    }, [item, searchTexts, tab]); // Dependencies

    return (
        <div key={keyValue} className={styles.job_list_item}>
            <div className={styles.job_heading}>
                <h2>{item.jobTitle}</h2>
            </div>
            <div className={styles.job_body}>
                <div className={styles.job_body_top}>
                    <div>
                        <h3 className={styles.job_category}>
                            {item.companyName}
                            <span>{}</span>
                        </h3>
                        <div className={styles.tags}>
                            {Utils.filterTags(item.tags || [], DATA.tags).map((tag) => (
                                <p key={tag.value}>{tag.value}</p>
                            ))}
                        </div>
                    </div>
                    <div>
                        <img className={styles.job_category_icon} src={Utils.getIcon(item.field)} alt={item.field} />
                    </div>
                </div>
                <div className={styles.job_description}>
                    <p>{Utils.truncateText(item.responsibilities || "")}</p>
                </div>
                <div className={styles.job_body_bottom}>
                    <div>
                        {item.numberOfVacancy && (
                            <div>
                                <h3>
                                    <img src={require("../../Assets/vacancy.png")} alt="Vacancy icon" />
                                    Vacancy :
                                </h3>
                                <p>{item.numberOfVacancy}</p>
                            </div>
                        )}
                        {(item.jobState || item.jobCity) && (
                            <div>
                                <h3>
                                    <img src={require("../../Assets/location.png")} alt="location icon" />
                                </h3>
                                <p>
                                    {" "}
                                    {item.jobCity ? `${item.jobCity},` : ""} {item.jobState}
                                </p>
                            </div>
                        )}
                        {item.salary && (
                            <div>
                                <h3>
                                    <img src={require("../../Assets/salary.png")} alt="salary icon" />
                                </h3>
                                <p>{item.salary} ₹</p>
                            </div>
                        )}
                        {item.createdAt && (
                            <div>
                                <h3>
                                    <img src={require("../../Assets/date.png")} alt="posted icon" /> Posted:
                                </h3>
                                <p>{Utils.formatDate(item.createdAt || "2024-07-13T13:47:14.405Z")}</p>
                            </div>
                        )}
                    </div>
                    <div>
                        <Link to={handleNavigation}>
                            <button>Full Details</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobContainer;
