import { useSelector } from "react-redux";
import { ROLES_LIST } from "../Constants";

export function formatDateString(dateString) {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate.replace(/\s(\d{4})/, ",$1");
}

export function capitalizeWords(sentence) {
    return sentence
        .split(" ")
        .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");
}

export const useVerifyRole = (roles) => {
    const { user } = useSelector((state) => state.auth);
    // Check if any role in the array matches the user's role
    return roles?.length > 0 && roles.some((role) => user?.roles?.USER === role);
};
