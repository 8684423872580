import React from "react";
import styles from "./index.module.css";

const TextInput = ({ name, label, placeholder, required, type, defaultValue }) => {
    return (
        <div className={styles.container}>
            <p>
                {label} {required && <span>*</span>}
            </p>
            <input placeholder={placeholder} type={type} name={name} value={defaultValue} required={required} />
        </div>
    );
};

export default TextInput;
