import React from "react";
import styles from "./index.module.css";
import SearchForm from "./SearchForm/index.jsx";

const SearchPopUp = ({ onClose }) => {
    return (
        <div className={styles.container_wrapper}>
            <div className={styles.container}>
                <h3>Search Jobs</h3>
                <span onClick={onClose} className={styles.cut_option}>
                    X
                </span>

                <SearchForm onClose={onClose} />
            </div>
        </div>
    );
};

export default SearchPopUp;
