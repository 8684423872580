export const fields = [
    // { value: "", label: "Select  Field" },
    { value: "", label: "All" },
    { value: "doctor", label: "Doctor" },
    { value: "nursing", label: "Nursing" },
    { value: "pharmacy", label: "Pharmacy" },
    { value: "technician", label: "Technician" },
];

const AllStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Delhi",
];

export const states = [
    { value: "", label: "Select State" },
    ...AllStates.map((state) => ({
        value: state.toLowerCase(),
        label: state,
    })),
];

export function capitalizeWords(str) {
    return str
        .split(" ") // Split the string into an array of words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(" "); // Join the words back into a single string
}
