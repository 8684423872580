import React, { useState } from "react";
import styles from "./index.module.css";
import TextInput from "../../../Components/InputTags/TextInput";
import Button from "../../../Components/InputTags/Button";
import { usePrivateJobContext } from "../../../../../Api/privatejobPosting";
import AlertEmailCheck from "./AlertEmailCheck";

const AuthenticationPopUp = ({ onClose }) => {
    const { registerUserFromPrivateJob } = usePrivateJobContext();
    const [checkEmailPopUp, setCheckEmailPopUp] = useState(false);

    return (
        <div className={styles.container_wrapper}>
            <div className={styles.container}>
                <div className={styles.body}>
                    <h2>
                        Employer Details{" "}
                        <span onClick={onClose} className={styles.cut_option}>
                            X
                        </span>
                    </h2>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            const formData = new FormData(event.target);
                            const formDataJSON = Object.fromEntries(formData.entries());
                            console.log(formDataJSON);
                            registerUserFromPrivateJob("/v1/user/register-user-private-job", formDataJSON, () => setCheckEmailPopUp(true));
                        }}
                        action=""
                    >
                        <TextInput label={"First and Last Name"} placeholder={"your full name"} name="name" required={true} type={"text"} />
                        <TextInput label={"Email"} placeholder={"your email address"} name="email" required={true} type={"email"} />
                        <TextInput label={"Contact Number"} placeholder={"xxxxxxxxxx"} name="phoneNumber" required={true} type={"text"} />

                        <TextInput label={"Website Link / Google Business Page"} placeholder={"yourcompany.com"} name="websiteLink" required={false} type={"text"} />

                        <Button label={"Submit"} type={"submit"} />
                    </form>
                </div>
            </div>
            {checkEmailPopUp && <AlertEmailCheck />}
        </div>
    );
};

export default AuthenticationPopUp;
