import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "../Components/ScrollTop/index.jsx";
import PrivateJobPosting from "../Pages/PrivateJobPosting/index.jsx";

// Lazy loading and memoizing route components
const NotFound = React.memo(lazy(() => import("../Pages/404/index.jsx")));
const Account = React.memo(lazy(() => import("../Pages/Account/index.jsx")));
const RegistrationList = React.memo(lazy(() => import("../Pages/RegistrationList/index.jsx")));
const Job = React.memo(lazy(() => import("../Pages/GovernmentJobPosting/index.jsx")));
const Home = React.memo(lazy(() => import("../Pages/Home/index.jsx")));
const PrivacyPolicy = React.memo(lazy(() => import("../Pages/PrivacyPolicy/index.jsx")));

const Routers = () => {
    return (
        <>
            <ScrollToTop />
            <Suspense
                fallback={
                    <div className="loading_container">
                        <img src="../publicAssets/companyLogo.jpg" alt="loading_icon" srcSet="" />
                    </div>
                }
            >
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/policy/:id" element={<PrivacyPolicy />} />
                    <Route path="/register" element={<RegistrationList />} />
                    <Route path="/job/:tab" element={<Job />} />
                    <Route path="/private-job/:tab" element={<PrivateJobPosting />} />
                    <Route path="/private-job/:tab/:subTab" element={<PrivateJobPosting />} />
                    <Route path="/account/:name" element={<Account />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </>
    );
};

export default Routers;
