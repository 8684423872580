import React from "react";
import styles from "./index.module.css";
import { Footer, Navbar } from "../../Layout/index.js";
import { useParams } from "react-router";
import JobListing from "./Pages/JobListing/index.jsx";
import JobForm from "./Pages/JobForm/index.jsx";
import NewsLetter from "../../Components/NewsLetter/index.jsx";

const PrivateJobPosting = () => {
    const { tab } = useParams();

    return (
        <div className={styles.container_wrapper}>
            <Navbar />

            <div className={styles.heading}></div>

            {(tab === "listing" || tab === "details") && <JobListing />}
            {(tab === "postjob" || tab === "authentication") && <JobForm />}

            <NewsLetter />
            <Footer />
        </div>
    );
};

export default PrivateJobPosting;
